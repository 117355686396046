import React, { Fragment, useRef, useEffect } from "react"
import { graphql } from "gatsby"

import Img from "gatsby-image"
import styled from "styled-components"
import _map from "lodash/map"


// import Swiper core and required components
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Keyboard, Navigation } from "swiper"
import "swiper/swiper-bundle.min.css"
/*import styleSwiper from 'swiper/swiper.scss';
import styleNavigation from 'swiper/components/navigation/navigation.scss';*/
import BtnPrimary from "../components/buttons/ButtonRounded"
import {
  Banner,
  PageWrapper,
  PageTitle,
  Spacer,
  Text2Col,
  ArrowLeftLink,
  ArrowRightLink,
  ArrowLeftIcon,
  ArrowRightIcon,
  Text,
} from "../components/Elements"
import { colors, mq } from "../consts/style"
import Seo from "../components/Seo"
import Boop from "../components/boop"
import Breadcrumb from "../components/breadcrumb"

/*console.log({ styleSwiper,styleNavigation  })*/
// install Swiper components
SwiperCore.use([Navigation, Keyboard])

const ArrowLeftLinkNav = styled(ArrowLeftLink)`
  position: absolute;
  bottom: 50%;
  left: 1rem;
  z-index: 1;
  cursor: pointer;
  transform: translate3d(0, 50%, 0);
  &.swiper-button-disabled {
    opacity: 0;
  }
`

const ArrowRightLinkNav = styled(ArrowRightLink)`
  position: absolute;
  bottom: 50%;
  right: 1rem;
  transform: translate3d(0, 50%, 0);
  z-index: 1;
  cursor: pointer;
  &.swiper-button-disabled {
    opacity: 0;
  }
`

const DiaporamaFullWidth = styled.div`
  z-index: 0;
  display: grid;
  display: block;
  .swiper-container {
    width: 100%;
    max-width: 100%;
    max-height: 100vh;
    min-height: 0;
    min-width: 0;
  }
  .swiper-slide .gatsby-image-wrapper {
    transition: all 0.4s ease;
    border-radius: 4px;
  }
  .swiper-slide-active .gatsby-image-wrapper {
    transition: all 0.4s ease;
  }

  .swiper-slide {
    transition: opacity 0.4s ease;
    width: auto;
    flex-shrink: 0;
    display: block;
    height: 100%;
    max-height: 100%;
  }
  .swiper-slide-active {
    opacity: 1;
    transition: opacity 0.4s ease;
  }

  .swiper-button-next {
    display: none;
  }

  .swiper-button-prev {
    display: none;
  }
`

const PageInnerActivite = styled.div`
  width: 100%;
  position: relative;
  display: grid;

  grid-template-columns: 2fr 1fr;
  grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  grid-gap: 5rem;

  ${mq.tablet` 
  grid-template-columns: 1fr;
  grid-template-columns:minmax(0, 1fr);
  grid-gap:1rem;
  `}
`

const LeftCol = styled.div`
  z-index: 0;
`

const EncartWrap = styled.div`
  margin-top: -20vh;
  grid-area: 1 / 2 / auto / auto;
  position: relative;
  ${mq.tablet` 
    grid-area: 1 / 1 / auto / auto;
    margin-top: -10vh;
    `}
`

const Encart = styled.div`
  background: ${colors.yellowLight};
  background: linear-gradient(
    90deg,
    rgba(241, 239, 212, 1) 5%,
    rgba(246, 234, 203, 1) 100%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: sticky;
  top: 150px;
  margin: 0px auto;
  text-align: left;
  max-width: 500px;
  padding: 3rem;
  margin-bottom: 2rem;
  ${mq.tablet` 
    position: relative;
    top: 0;
    padding:2rem;
  `}
`

const EncartTitle = styled.div`
  font-family: Wendy One;
  text-align: left;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 2.2rem;
`

const EncartText = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.4;
  ul {
    list-style: none;
    padding-left: 2rem;
    li {
      margin-bottom: 0.6rem;
    }
    li:before {
      content: "";
      color: ${colors.blue};
      font-weight: bold;
      display: inline-block;
      /* width: 2rem;*/
      margin-left: -20px;
      margin-right: 10px;
      border-radius: 100%;
      width: 10px;
      height: 10px;
      background: ${colors.yellow};
      border: 2px solid ${colors.dark};
    }
  }
`

const EncartBtnWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  flex-wrap: wrap;
`

const Groupe = ({ data, pageContext, location }) => {
  const {
    titre,
    titreAlt,
    seoMetaTags,
    diaporamaPhotos,
    description,
    modular,
    guidapKey,
    guidapKeyV2, 
  } = data.groupe
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  const handleClick = (guidapKey) => window.GUIDAP.booking.call('bolleneaventure.guidap.co', `${guidapKey}?lang=${pageContext.locale}`)
 // console.log(location);
  // Google ADS Conversion
 /* useEffect(() => {
    //console.log('useeffect triggered');
    (typeof window !== "undefined"  && (location.pathname=== ("/groupes/anniversaire-cousinade" || "/groupes/anniversaire-cousinade/" ))) && gtag('event', 'conversion', {'send_to': 'AW-919879518/hYcMCLyF5s4DEN7-0LYD'});
  });*/

  return (
    <Fragment>
      <Seo meta={seoMetaTags} />

      <PageWrapper>
        <Banner height="50vh">
          <Img fluid={data.groupe.imagePrincipale.fluid} />
        </Banner>
        <Breadcrumb breaddata={pageContext.breadcrumb} />
        <PageInnerActivite>
          <LeftCol>
            <PageTitle dangerouslySetInnerHTML={{ __html: titreAlt }} />
            <Text2Col>
              <Text dangerouslySetInnerHTML={{ __html: description }} />
            </Text2Col>
            <Spacer />
            <DiaporamaFullWidth>
              <Swiper
                navigation
                spaceBetween={10}
                slidesPerView={1}
                keyboard
                grabCursor
                freeMode
                breakpoints={{
                  // when window width is >= 640px
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  // when window width is >= 1024px
                  1024: {
                    slidesPerView: 1.2,
                    spaceBetween: 20,
                  },
                }}
                onInit={swiper => {
                  swiper.params.navigation.prevEl = prevRef.current
                  swiper.params.navigation.nextEl = nextRef.current
                  swiper.navigation.update()
                }}
              >
                {_map(diaporamaPhotos, image => (
                  <SwiperSlide key={image.originalId}>
                    <Img fluid={image.fluid} />
                  </SwiperSlide>
                ))}

                <ArrowLeftLinkNav ref={prevRef}>
                  <Boop scale={1.05} timing={200}>
                    <ArrowLeftIcon title="Précédent" />
                  </Boop>
                </ArrowLeftLinkNav>

                <ArrowRightLinkNav ref={nextRef}>
                  <Boop scale={1.05} timing={200}>
                    <ArrowRightIcon title="Suivant" />
                  </Boop>
                </ArrowRightLinkNav>
              </Swiper>
            </DiaporamaFullWidth>
          </LeftCol>

          <EncartWrap>
            <Encart>
              <EncartTitle>{titre}</EncartTitle>
              {modular.map(block => (
                <React.Fragment key={block.id}>
                
                  {block.model.apiKey === "texte" && (
                    <EncartText
                      dangerouslySetInnerHTML={{ __html: block.texteEncart }}
                    />
                  )}
                </React.Fragment>
              ))}
              <EncartBtnWrapper>
                {modular.map(block => (
                  <React.Fragment key={block.id}>
                  {
                    block.model.apiKey === "bouton_guidap" && (    
                     <>
                      <Boop scale={1.02} timing={100} ><guidap-activity-reserve-button activity-uuid={block.codeGuidapV2}>{block.texteDuBouton}</guidap-activity-reserve-button> </Boop>
                      </>
                      )
                    }
                    {block.model.apiKey === "bouton" && (
                      block.boutonGuidap ? 
                     <>{/*   <BtnPrimary as="a" href="#" onClick={()=>handleClick(guidapKey)}>{block.texteDuBouton}</BtnPrimary>   */}
                     <Boop scale={1.02} timing={100} >
                      <guidap-activity-reserve-button activity-uuid={guidapKeyV2}>{block.texteDuBouton}</guidap-activity-reserve-button>
                    </Boop></>
                      :
                      <BtnPrimary
                        external={
                          block.lienExterne ? block.lienExterne : undefined
                        }
                        to={block.lienDuBouton}
                        as={block.lienExterne && "a"}
                      >
                        {block.texteDuBouton}
                      </BtnPrimary>
                      
                     
                    )
                      }
                  </React.Fragment>
                ))}
              </EncartBtnWrapper>
            </Encart>
          </EncartWrap>
        </PageInnerActivite>

        <Spacer />
      </PageWrapper>
    </Fragment>
  )
}

export const projectQuery = graphql`
  query($slug: String!, $locale: String!) {
    groupe: datoCmsGroupe(slug: { eq: $slug }, locale: { eq: $locale }) {
      titreAlt
      titre
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      description
      id
      imagePrincipale {
        fluid(
          maxWidth: 1980
          forceBlurhash: false,
          imgixParams: { fm: "jpg", auto: "compress" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      slug
      guidapKey
      guidapKeyV2
      diaporamaPhotos {
        originalId
        fluid(
          maxHeight: 600,
          imgixParams: { fit: "crop", w: "800", h: "600", fm: "jpg",auto: "compress" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      modular {


        ... on DatoCmsBoutonGuidap {
          model {
            apiKey
          }
          id
          lienDuBouton
          codeGuidap
          codeGuidapV2
          texteDuBouton
        }
      
      
        ... on DatoCmsBouton {
          model {
            apiKey
          }
          id
          lienDuBouton
          lienExterne
          boutonGuidap
          texteDuBouton
        }
        ... on DatoCmsTexte {
          model {
            apiKey
          }
          id
          texteEncart
        } 
      }
    }
  }
`

export default Groupe
